import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Grid, Paper, makeStyles, Button, Box } from '@material-ui/core';
import QualityHome from './components/QualityHome';
import { Link } from 'react-router-dom';
import { interpolateColors } from '../../utils/toast';
import Vendas from './components/Vendas';
import RelComm from './components/RelComm';
import CotasCon from './components/CotasCon';
import { TitleCard } from '../../base/TitleCard';
import { Icon } from '@iconify/react';
import barcodeIcon from '@iconify-icons/mdi/barcode';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import BarChartIcon from '@material-ui/icons/BarChart';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import HomeIcon from '@material-ui/icons/Home';
import { connect } from 'react-redux';
import { UserData } from '../../Models/User';
import api from '../../service/api';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '100%',
  },
  iconLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
  },
  homeIcon: {
    borderRadius: '0px',
    height: '150px',
    overflow: 'hidden',
    color: 'white',
  },
  frontButt: {
    minHeight: '100%',
  },
  iconText: {
    height: '50px',
    fontSize: '12px',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    overflow: 'hidden',
    textTransform: 'capitalize',
  },

  icon: {
    color: 'white',
  },
}));

interface IHomeItem {
  title: string;
  icon: any;
  link: string;
  backgroundColor: string;
}

interface IHome {
  userData: UserData;
  loading: boolean;
  grupoTela: any[];
}

const HomeItem = ({ title, icon, link, backgroundColor }: IHomeItem) => {
  const classes = useStyles();

  interpolateColors('#FFFFFF', 'FF00FF', 10);

  return (
    <Grid item xs={12} md={3} lg>
      <Paper
        style={{ backgroundColor: backgroundColor }}
        className={classes.homeIcon}
      >
        <Link to={link}>
          <Button fullWidth className={classes.frontButt}>
            <div className={classes.iconLayout}>
              {icon}
              <div className={classes.iconText}>{title}</div>
            </div>
          </Button>
        </Link>
      </Paper>
    </Grid>
  );
};

const itemss = [
  {
    icon: <Icon icon={barcodeIcon} style={{ fontSize: 36 }} color="white" />,
    title: '2ª Via de Boleto',
    link: '/Vendas/BoletoAvulso',
    backgroundColor: '#01579B',
  },
  {
    icon: <Icon icon={barcodeIcon} style={{ fontSize: 36 }} color="white" />,
    title: 'Boleto Adesão',
    link: '/Vendas/BoletoAdesao',
    backgroundColor: '#0277BD',
  },
  {
    icon: <ViewListOutlinedIcon htmlColor="white" fontSize="large" />,
    title: 'Tabela de Preços',
    link: '/Vendas/TabelaDePrecos',
    backgroundColor: '#0288D1',
  },
  {
    icon: <FileCopyOutlinedIcon htmlColor="white" fontSize="large" />,
    title: 'Res Assembléia',
    link: '/Assembleia/Resultados',
    backgroundColor: '#039BE5',
  },
  {
    icon: <LocalAtmIcon htmlColor="white" fontSize="large" />,
    title: 'Oferta de Lances',
    link: '/Lances/OfertaLance',
    backgroundColor: '#03A9F4',
  },
  {
    icon: <BarChartIcon htmlColor="white" fontSize="large" />,
    title: 'List. Documentos (todo)',
    link: '',
    backgroundColor: '#29B6F6',
  },
  {
    icon: <InsertDriveFileOutlinedIcon htmlColor="white" fontSize="large" />,
    title: 'Extrato Cota',
    link: '/Vendas/ExtratoCota',
    backgroundColor: '#4FC3F7',
  },
  {
    icon: <AccountBalanceOutlinedIcon htmlColor="white" fontSize="large" />,
    title: 'Débito em Conta',
    link: '/Vendas/DebitoConta',
    backgroundColor: '#81D4FA',
  },
  {
    icon: <CakeOutlinedIcon htmlColor="white" fontSize="large" />,
    title: 'Aniversariantes',
    link: 'Relatorios/Aniversarios',
    backgroundColor: '#B3E5FC',
  },
  {
    icon: <AccountBoxIcon htmlColor="white" fontSize="large" />,
    title: 'Meus Dados',
    link: '/mydata',
    backgroundColor: '#D6F2FF',
  },
];

const Home = ({ userData, grupoTela }: IHome) => {
  const classes = useStyles();
  const [dataComissoes, setDataComissoes] = useState([]);
  const [dataVendas, setDataVendas] = useState([]);
  const [dataCotas, setDataCotas] = useState([]);
  const [loadingRellComm, setLoadingRellComm] = useState(false);
  const [loadingVendas, setLoadingVendas] = useState(false);
  const [loadingCotaCon, setLoadingCotaCon] = useState(false);
  const [permissaoDashQuality, setPermissaoDashQuality] = useState(false);
  const [permissaoDashComissao, setPermissaoDashComissao] = useState(false);
  const [permissaoDashCota, setPermissaoDashCota] = useState(false);
  const [permissaoDashVendas, setPermissaoDashVendas] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  var date = new Date();
  const firstDay = new Date(date.getFullYear() - 4, date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const firstDayRellCom = new Date(date.getFullYear() - 2, date.getMonth(), 1);
  var periodoTostring =
    'De ' +
    firstDay.toLocaleDateString() +
    ' a ' +
    lastDay.toLocaleDateString();

  var periodoRelatorioComissoesTostring =
    'De ' +
    firstDayRellCom.toLocaleDateString() +
    ' a ' +
    lastDay.toLocaleDateString();

  var classificacao = userData.pontoVenda?.classificacao || '';

  const getItens = () => {
    var itemsPermitidos = [];
    if (
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some(
          (te) => te.comando === '/Vendas/BoletoAvulso'
        )
      )
    ) {
      itemsPermitidos.push({
        icon: (
          <Icon icon={barcodeIcon} style={{ fontSize: 36 }} color="white" />
        ),
        title: '2ª Via de Boleto',
        link: '/Vendas/BoletoAvulso',
        backgroundColor: '#01579B',
      });
    }
    if (
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some(
          (te) => te.comando === '/Vendas/BoletoAdesao'
        )
      )
    ) {
      itemsPermitidos.push({
        icon: (
          <Icon icon={barcodeIcon} style={{ fontSize: 36 }} color="white" />
        ),
        title: 'Boleto Adesão',
        link: '/Vendas/BoletoAdesao',
        backgroundColor: '#0277BD',
      });
    }
    if (
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some(
          (te) => te.comando === '/Vendas/TabelaDePrecos'
        )
      )
    ) {
      itemsPermitidos.push({
        icon: <ViewListOutlinedIcon htmlColor="white" fontSize="large" />,
        title: 'Tabela de Preços',
        link: '/Vendas/TabelaDePrecos',
        backgroundColor: '#0288D1',
      });
    }
    if (
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some(
          (te) => te.comando === '/Assembleia/Resultados'
        )
      )
    ) {
      itemsPermitidos.push({
        icon: <FileCopyOutlinedIcon htmlColor="white" fontSize="large" />,
        title: 'Res Assembléia',
        link: '/Assembleia/Resultados',
        backgroundColor: '#039BE5',
      });
    }
    if (
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some((te) => te.comando === '/Lances/OfertaLance')
      )
    ) {
      itemsPermitidos.push({
        icon: <LocalAtmIcon htmlColor="white" fontSize="large" />,
        title: 'Oferta de Lances',
        link: '/Lances/OfertaLance',
        backgroundColor: '#03A9F4',
      });
    }
    if (
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some((te) => te.comando === '/Vendas/ExtratoCota')
      )
    ) {
      itemsPermitidos.push({
        icon: (
          <InsertDriveFileOutlinedIcon htmlColor="white" fontSize="large" />
        ),
        title: 'Extrato Cota',
        link: '/Vendas/ExtratoCota',
        backgroundColor: '#4FC3F7',
      });
    }
    if (
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some((te) => te.comando === '/Vendas/DebitoConta')
      )
    ) {
      itemsPermitidos.push({
        icon: <AccountBalanceOutlinedIcon htmlColor="white" fontSize="large" />,
        title: 'Débito em Conta',
        link: '/Vendas/DebitoConta',
        backgroundColor: '#81D4FA',
      });
    }
    if (
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some(
          (te) => te.comando === '/Relatorios/Aniversarios'
        )
      )
    ) {
      itemsPermitidos.push({
        icon: <CakeOutlinedIcon htmlColor="white" fontSize="large" />,
        title: 'Aniversariantes',
        link: '/Relatorios/Aniversarios',
        backgroundColor: '#B3E5FC',
      });
    }
    itemsPermitidos.push({
      icon: <AccountBoxIcon htmlColor="white" fontSize="large" />,
      title: 'Meus Dados',
      link: '/mydata',
      backgroundColor: '#D6F2FF',
    });

    setItems(itemsPermitidos);
  };

  const GetPainelComissao = async () => {
    setLoadingRellComm(true);
    const { data: tableData } = await api.get('/Relatorio/PainelComissao', {
      params: {
        comissionado: userData.pontoVenda?.codigo,
        dataInicial: firstDayRellCom.toISOString().substring(0, 10),
        dataFinal: lastDay.toISOString().substring(0, 10),
      },
    });
    setDataComissoes(tableData);
    setLoadingRellComm(false);
  };

  const GetPainelCotas = async () => {
    setLoadingCotaCon(true);
    const { data: tableData } = await api.get(
      '/Relatorio/GestaoCarteiraOverview',
      {
        params: {
          dataInicial: firstDay.toISOString().substring(0, 10),
          dataFinal: lastDay.toISOString().substring(0, 10),
          tipoPeriodo: 'informado',
          filter: 'acbne,conmes',
        },
      }
    );
    setDataCotas(tableData);
    setLoadingCotaCon(false);
  };

  const GetPainelVendas = async () => {
    setLoadingVendas(true);
    const { data: tableData } = await api.get('/RelatorioVendas/PainelVendas', {
      params: {
        comissionado: userData.pontoVenda?.codigo,
        dt_Inicial: firstDay.toISOString().substring(0, 10),
        dt_Final: lastDay.toISOString().substring(0, 10),
      },
    });
    setDataVendas(tableData);
    setLoadingVendas(false);
  };

  const VerificaPermissoes = () => {
    setPermissaoDashQuality(
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some(
          (te) => te.comando === '/ExtratoClassificacao'
        )
      )
    );
    setPermissaoDashComissao(
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some(
          (te) => te.comando === '/Relatorios/Comissoes'
        )
      )
    );
    setPermissaoDashCota(
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some(
          (te) => te.comando === '/Relatorios/GestaoCarteira'
        )
      )
    );
    setPermissaoDashVendas(
      grupoTela.some((grupo) =>
        (grupo.tela as any[]).some(
          (te) => te.comando === '/Vendas/PainelVendas'
        )
      )
    );
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  let loadData = () => {
    if (permissaoDashComissao) GetPainelComissao();
    if (permissaoDashCota) GetPainelCotas();
    if (permissaoDashVendas) GetPainelVendas();
    getItens();
  };
  useEffect(VerificaPermissoes, [grupoTela]);
  useEffect(loadData, [
    permissaoDashVendas,
    permissaoDashCota,
    permissaoDashComissao,
  ]);

  return (
    <Box className={classes.container}>
      <TitleCard breadcrumbsText="Home" breadcrumbsIcon={<HomeIcon />}>
        Bem vindo ao Portal do Consórcio
      </TitleCard>

      <Grid container spacing={0}>
        {items.map((x) => (
          <HomeItem
            link={x.link}
            icon={x.icon}
            backgroundColor={x.backgroundColor}
            title={x.title}
          />
        ))}
      </Grid>

      <Grid container style={{ marginTop: 3 }}>
         {false && permissaoDashQuality && ( //False para ocultar provisóriamente no chamado BYH-76
          <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
            <Link to={'/ExtratoClassificacao'}>
              <Paper className={fixedHeightPaper}>
                <QualityHome classificacao={classificacao} />
              </Paper>
            </Link>
          </Grid>
        )}
        {permissaoDashVendas && (
          <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
            <Link to={'/Vendas/PainelVendas'}>
              <Paper className={fixedHeightPaper}>
                <Vendas
                  itens={dataVendas}
                  loadingVendas={loadingVendas}
                  data={periodoTostring}
                />
              </Paper>
            </Link>
          </Grid>
        )}
        {permissaoDashComissao && (
          <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
            <Link to={'/Relatorios/Comissoes'}>
              <Paper className={fixedHeightPaper}>
                <RelComm
                  itens={dataComissoes}
                  loadingRellComm={loadingRellComm}
                  data={periodoRelatorioComissoesTostring}
                />
              </Paper>
            </Link>
          </Grid>
        )}
        {permissaoDashCota && (
          <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
            <Link to={'/Relatorios/GestaoCarteira'}>
              <Paper className={fixedHeightPaper}>
                <CotasCon
                  itens={dataCotas}
                  loadingCotaCon={loadingCotaCon}
                  data={periodoTostring}
                />
              </Paper>
            </Link>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.systemParameter.loading,
  userData: state.user.userData,
  grupoTela: state.user.grupoTela,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadUserData: () => {
    // dispatch(getMyData())
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
