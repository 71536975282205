import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { Icon, makeStyles } from '@material-ui/core';

const corFont = '#BFDCF1';
const sizeFont = 4;
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: '#333',
    fontSize: sizeFont,
    transform: 'scale(0.5)',
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'red',
  },
  openCarousel: {
    color: corFont,
    fontSize: sizeFont,
    backgroundColor: '#0277b8',
    '&:hover': { backgroundColor: '#026BA5 !important' },
  },
  openCollapse: {
    color: corFont,
    fontSize: sizeFont,
    backgroundColor: '#0277b8',
    '&:hover': { backgroundColor: '#026BA5 !important' },
  },
  listItem: {
    backgroundColor: '#0288D1',
    color: corFont,
    '&:hover': { backgroundColor: '#026BA5 !important' },
  },
  itemChildren: {
    color: corFont,
    backgroundColor: '#0277b8',
    fontSize: sizeFont,
  },
  itemIcon: {
    color: corFont,
    fontSize: 14,
  },
  textWithoutIcon: {
    paddingLeft: '24px',
  },
  iconWrapper: { minWidth: '16px', marginRight: '16px' },
}));

const menuItemTypographyStyle = {
  fontSize: 14,
  overflow: 'hidden',
};

const MenuItem = ({ grupo, hoverAndCloseSidebar }: any) => {
  const Component = grupo.nome !== 'Home' ? MultiLevel : SingleLevelGrupo;
  return (
    <>
      <Component grupo={grupo} hoverAndCloseSidebar={hoverAndCloseSidebar} />
    </>
  );
};

const SingleLevelGrupo = ({ grupo, hoverAndCloseSidebar }: any) => {
  const classes = useStyles();
  var tela = grupo.tela[0];
  const content = (
    <Link to={tela.comando} className={classes.link}>
      <ListItem button className={classes.itemChildren}>
        <ListItemIcon className={classes.iconWrapper}>
          <Icon className={classes.itemIcon}>{grupo.icone}</Icon>
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            style: menuItemTypographyStyle,
          }}
          className={
            grupo.icone || grupo.icone == '' ? '' : classes.textWithoutIcon
          }
          primary={grupo.nome}
        />
      </ListItem>
    </Link>
  );
  return content;
};

const SingleLevel = ({ tela }: any) => {
  const classes = useStyles();

  const content = (
    <ListItem button className={classes.itemChildren}>
      <ListItemIcon
        style={{ paddingLeft: '32px' }}
        className={classes.iconWrapper}
      >
        <Icon className={classes.itemIcon}>{tela.icone}</Icon>
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          style: menuItemTypographyStyle,
        }}
        className={
          tela.icone || tela.icone == '' ? '' : classes.textWithoutIcon
        }
        primary={tela.titulo}
      />
    </ListItem>
  );

  if (tela.comando) {
    if (tela.comando.startsWith('http')) {
      return (
        <a
          href={tela.comando}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          {content}
        </a>
      );
    } else {
      return (
        <Link to={tela.comando} className={classes.link}>
          {content}
        </Link>
      );
    }
  } else {
    return content;
  }
};

const MultiLevel = ({ grupo, hoverAndCloseSidebar }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  if (grupo.tela) {
    return (
      <>
        <ListItem
          button
          onClick={handleClick}
          className={
            open && hoverAndCloseSidebar
              ? classes.openCarousel
              : classes.listItem
          }
        >
          <ListItemIcon className={classes.iconWrapper}>
            <Icon className={classes.itemIcon}>{grupo.icone}</Icon>
          </ListItemIcon>

          <ListItemText
            primaryTypographyProps={{
              style: menuItemTypographyStyle,
            }}
            primary={grupo.nome}
          />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse
          in={open && hoverAndCloseSidebar}
          timeout="auto"
          unmountOnExit
          className={classes.openCollapse}
        >
          <List component="div" disablePadding>
            {grupo.tela
              // .filter((telaInfo: any) => telaInfo.comando != "/Relatorios/GestaoCarteira")
              .map((child: any, key: any) => (
                <SingleLevel key={key} tela={child} />
              ))}
          </List>
        </Collapse>
      </>
    );
  } else {
    return <></>;
  }
};

interface IMenuItens {
  user: any;
  hoverAndCloseSidebar: boolean;
}

const MenuItems = ({ user, hoverAndCloseSidebar }: IMenuItens) => {
  if (user.grupoTela.length > 0) {
    return (
      <>
        {user.grupoTela
        .filter((telaInfo: any) => telaInfo.nome != "Quality Performance")
        .map((item: any, key: number) => (
          <MenuItem
            key={key}
            grupo={item}
            hoverAndCloseSidebar={hoverAndCloseSidebar}
          />
        ))}
      </>
    );
  } else return <></>;
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    grupoTelas: state.user.grupoTela,
  };
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItems);
